import { Swiper, Pagination, EffectFade, Autoplay } from '/js/vendor/swiper.custom.js';


document.addEventListener('DOMContentLoaded', function() {
	var swiper = new Swiper('.wide-slider', {
		modules: [Pagination,EffectFade,Autoplay],

		loop: true,
		effect: 'fade',
		speed: 400,
		autoplay: {
			delay: 4000,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
		},
		longSwipesRatio: .3
	});

	document.addEventListener("visibilitychange", () => {
		if (document.hidden) swiper.autoplay.pause()
		else swiper.autoplay.resume();
	})
})
